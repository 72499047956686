typeof $ === 'function' && $(document).ready(function () {
    window.pageData = {};
  
    const el = document.getElementById('page-data');
  
    if (el) {
      for (const attr of el.getAttributeNames()) {
        if (attr.startsWith('data-var-')) {
          const attrName = attr.substring('data-var-'.length);
          const attrNameFormat = attrName.replace(/-([a-z])/g, g => g[1].toUpperCase());
    
          window.pageData[attrNameFormat] = el.getAttribute(attr);
        }
      }
    }
  
    if ('page' in window) {
      import(`./pages/${window.page}.js`).catch((err) => {
        if (err && err.message.includes('Unknown variable dynamic import')) {
          return false;
        }
  
        console.error(err);
      });
    }
  });
  